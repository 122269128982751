import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import { dayjsWapper } from '~/utils/tool'

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    authToken: null,
    headquarter: null,
    company: null,
    settings: null,
    client: null,
    goal_term: null,
    design_datas: {
      img_logo: {},
      img_header: {},
      img_side: {},
      img_trial_bk: {},
      primary_color: "#2F54EB",
      header_color: "#1D1E1F",
      side_color: "#1D1E1F",
      body_color: "#F7F9FC",
      footer_color: "#FFFFFF",
      trial_color: "#F7F9FC",
      header_text_color: "#FFFFFF",
      side_text_color: "#FFFFFF",
      body_text_color: "#0A1233",
      footer_text_color: "#7A8099",
      trial_text_color: "#0A1233",
    },
    new_alert_size: 0,

    // ライフログ
    showLogChart: true,

    // 種目
    trainingEventDoneOnly: false,

    // push
    lastUpdatedAtClient: null,
    oneSignalDeviseToken: null,

    // old
    target_company: null,
    company_groups: [],
    able_companies: [],
    calendars: [],
    resources: [],
    menus: [],
    courses: [],
    item_categories: [],
    items: [],
    training_categories: [],
    training_events: [],
    training_machines: [],
    training_muscles: [],
    client_training_events: [],
    time_zone: null,
    survey: null,
    prev_lesson: null,
    next_lesson: null,
    prev_has_data_lesson: null,
    prev_has_data_client_trend: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },
  
  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setHeadquarter(state, headquarter) {
    if(state.headquarter == null || state.headquarter.design_updated_at !== headquarter.design_updated_at){
      state.design_datas.img_logo = headquarter.img_logo
      state.design_datas.img_header = headquarter.img_header
      state.design_datas.img_side = headquarter.img_side
      state.design_datas.img_trial_bk = headquarter.img_trial_bk
      state.design_datas.primary_color = headquarter.design_settings.primary_color
      state.design_datas.header_color = headquarter.design_settings.header_color
      state.design_datas.side_color = headquarter.design_settings.side_color
      state.design_datas.body_color = headquarter.design_settings.body_color
      state.design_datas.footer_color = headquarter.design_settings.footer_color
      state.design_datas.trial_color = headquarter.design_settings.trial_color
      state.design_datas.header_text_color = headquarter.design_settings.header_text_color
      state.design_datas.side_text_color = headquarter.design_settings.side_text_color
      state.design_datas.body_text_color = headquarter.design_settings.body_text_color
      state.design_datas.footer_text_color = headquarter.design_settings.footer_text_color
      state.design_datas.trial_text_color = headquarter.design_settings.trial_text_color
    }
    state.headquarter = headquarter
  },
  setCompany(state, company) {
    // 店舗設定でうわ書く
    if(state.company == null || state.company.design_updated_at !== company.design_updated_at){
      state.design_datas.img_logo = company.img_logo.url ? company.img_logo : state.headquarter.img_logo
    }
    state.company = company
  },
  setShowLogChart(state, showLogChart){
    state.showLogChart = showLogChart
  },
  setTrainingEventDoneOnly(state, trainingEventDoneOnly){
    state.trainingEventDoneOnly = trainingEventDoneOnly
  },
  setClient(state, client) {
    state.client = client
  },
  setLastUpdatedAt(state, payload){
    state.lastUpdatedAtClient = payload
  },
  setNewAlertSize(state, new_alert_size) {
    state.new_alert_size = new_alert_size
  },
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },
  clearAllData(state) {
    state.authToken = null
    state.client = null
    window.storeCtl.commit("master/reset")
  },
  logout(state) {
    $nuxt.$router.push(`/login/`)
    Object.assign(state, getDefaultState())
    window.storeCtl.commit("master/reset")
  },

  // resetold
  clearOldData(state) {
    state.target_company = null
    state.company_groups = null
    state.able_companies = null
    state.calendars = null
    state.resources = null
    state.menus = null
    state.courses = null
    state.item_categories = null
    state.items = null
    state.training_categories = null
    state.training_events = null
    state.training_machines = null
    state.training_muscles = null
    state.client_training_events = null
    state.time_zone = null
    state.survey = null
    state.prev_lesson = null
    state.next_lesson = null
    state.prev_has_data_lesson = null
    state.prev_has_data_client_trend = null
  },

}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
}

// 戻り地はisBeforeRequest
export const actions = {
  async refreshToken(context) {
    await httpClient
      .post('/cli/account/refresh_token.json')
      .then(async res => {
        if (res.data.status === 'success') {
          if(res.data.data.is_admin === false) context.commit('setAuthToken', res.data.data.auth_token)
          console.log("refreshToken",res.data.data.expire_at)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
  },

  async getUpdate(context){
    await httpClient
    .get('/cli/has_update.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        // alert size
        context.commit("setNewAlertSize", res.data.data.new_alert_size)

        // マスタの更新が新しければマスタGET
        let latest_update_at = dayjsWapper(res.data.data.client_updated_at)
        let last_update_at = dayjsWapper(context.state.lastUpdatedAtClient)
        if(context.state.lastUpdatedAtClient === null || latest_update_at.isAfter(last_update_at)){
          context.commit("setLastUpdatedAt", res.data.data.client_updated_at)

          // ここで一度マスターをすべて取得
          await window.storeCtl.dispatch('master/getClient')
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      // window.storeCtl.commit("loading/stop")
    })
  },

  async clearAppBatch(){
    if(!window.storeCtl.state.userAgent.isIosApp) return
    try{
      var json = {
        "call_name": "clearBadge",
        "timestamp": dayjsWapper().format("YYYY-MM-DD HH:mm:ss")
      }  
      var json_str = JSON.stringify(json)
      window.webkit.messageHandlers.clearBadge.postMessage(json_str);
    }catch(e){
    }
  },

  //// push
  // ログインしていて
  // LocalStrage取れていて
  // StoreとLocalstrageの値が違う
  // サーバー同期処理
  async updateOneSignalDeviseToken(context){
    const onesignal_user_id = localStorage.getItem('onesignal_user_id'); 
    if(
      onesignal_user_id &&
      window.storeCtl.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ){
      await httpClient
      .post('/cli/account/update_onesignal_device_token.json', {
        onesignal_device_token: onesignal_user_id
      })
      .then((res) => {
        if (res.data.status === 'success') {
          window.storeCtl.commit("auth/setOneSignalDeviseToken", onesignal_user_id)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    }
  },

}
