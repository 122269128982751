import { httpClient } from '~/utils/axios'
import _ from 'lodash';

const getDefaultState = () => {
  return {
    // 予約関連マスタ
    target_company: null,
    company_groups: [],
    able_companies: [],
    calendars: [],
    resources: [],
    menus: [],

    // その他マスタ
    courses: [],
    item_categories: [],
    items: [],
    training_categories: [],
    training_events: [],
    training_machines: [],
    training_muscles: [],
    client_training_events: [],
    time_zone: null,
    survey: null,

    // dashboard
    prev_lesson: null,
    next_lesson: null,
    prev_has_data_lesson: null,
    prev_has_data_client_trend: null,

  }
}

export const state = () => (getDefaultState())
export const mutations = {
  setTargetCompany(state, target_company) {
    state.target_company = target_company
  },
  setCompanyGroups(state, company_groups) {
    state.company_groups = company_groups
  },
  setAbleCompanies(state, able_companies) {
    state.able_companies = able_companies
  },
  setCalendars(state, calendars) {
    calendars = _.sortBy(calendars, "sort")
    state.calendars = calendars
  },
  setResources(state, resources) {
    resources = _.sortBy(resources, "sort")
    state.resources = resources
  },
  setMenus(state, menus) {
    menus.forEach(m => {
      m.label = `${m.name}/${m.need_ticket_size}枚/${m.max_seat_size}人`
    })
    menus = _.sortBy(menus, "sort")
    state.menus = menus
  },
  setCourses(state, courses) {
    courses.forEach(c => {
      c.label = `${c.name}/${c.ticket_number}枚/${c.price}円`
    })
    courses = _.sortBy(courses, "sort")
    state.courses = courses
  },
  setItemCategories(state, item_categories) {
    item_categories = _.sortBy(item_categories, ["sort"])
    state.item_categories = item_categories
  },
  setItems(state, items) {
    items.forEach(it => {
      it.label = `${it.name_inner} / ${it.price}円(${it.tax_rate}%) / ${it.manage_stock ? '在庫:' + it.stock_size + '個' : null}`
    })
    items = _.sortBy(items, ["sort"])
    state.items = items
  },
  setTrainingCategories(state, training_categories) {
    training_categories = _.sortBy(training_categories, ["sort"])
    state.training_categories = training_categories
  },
  setTrainingEvents(state, training_events) {
    training_events = _.orderBy(
      training_events,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_events = training_events
  },
  setClientTrainingEvents(state, client_training_events) {
    state.client_training_events = client_training_events
  },
  setTrainingMuscles(state, training_muscles) {
    training_muscles = _.orderBy(
      training_muscles,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_muscles = training_muscles
  },
  setTrainingMachines(state, training_machines) {
    training_machines = _.orderBy(
      training_machines,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_machines = training_machines
  },
  setTimeZone(state, time_zone) {
    state.time_zone = time_zone
  },
  clearSurvey(state){
    state.survey = null
  },
  setSurvey(state, survey){
    state.survey = survey
  },

  setGoalTerm(state, goal_term) {
    state.goal_term = goal_term
  },
  setDashboard(state, payload) {
    state.prev_lesson = payload.prev_lesson
    state.next_lesson = payload.next_lesson
    state.prev_has_data_lesson = payload.prev_has_data_lesson
    state.prev_has_data_client_trend = payload.prev_has_data_client_trend
  },

  reset(state) {
    Object.assign(state, getDefaultState())
  },

  
}
export const getters = {
}
export const actions = {
  // マスタ一式取得
  async getClient(context){
    await httpClient
    .get('/cli/account/show.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        window.storeCtl.commit("auth/setHeadquarter", res.data.data.headquarter)
        window.storeCtl.commit("auth/setCompany", res.data.data.company)
        window.storeCtl.commit("auth/setClient", res.data.data.client)
        context.commit("setCompanyGroups", res.data.data.company_groups)
        context.commit("setGoalTerm", res.data.data.goal_term)
        context.commit("setCourses", res.data.data.courses)
        context.commit("setItemCategories", res.data.data.item_categories)
        context.commit("setItems", res.data.data.items)
        context.commit("setTrainingCategories", res.data.data.training_categories)
        context.commit("setTrainingEvents", res.data.data.training_events)
        context.commit("setTrainingMuscles", res.data.data.training_muscles)
        context.commit("setTrainingMachines", res.data.data.training_machines)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  async getReserveMaster(context, company_uid){
    await httpClient
    .get(`/cli/account/show_reserve_master.json?company_uid=${company_uid}`)
    .then(async (res) => {
      if (res.data.status === 'success') {
        window.storeCtl.commit("auth/setSettings", res.data.data.settings)
        window.storeCtl.commit("auth/setCompany", res.data.data.company)
        window.storeCtl.commit("auth/setClient", res.data.data.client)
        context.commit("setTargetCompany", res.data.data.target_company)
        context.commit("setCompanyGroups", res.data.data.company_groups)
        context.commit("setAbleCompanies", res.data.data.able_companies)
        context.commit("setCalendars", res.data.data.calendars)
        context.commit("setResources", res.data.data.resources)
        context.commit("setMenus", res.data.data.menus)
        context.commit("setTimeZone", res.data.data.time_zone)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  // レッスンデータ取得
  async getDashboard(context){
    await httpClient
    .get('/cli/account/dashboard.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        window.storeCtl.commit("auth/setCompany", res.data.data.company)
        window.storeCtl.commit("auth/setClient", res.data.data.client)
        context.commit("setDashboard", {
          prev_lesson: res.data.data.prev_lesson,
          next_lesson: res.data.data.next_lesson,
          prev_has_data_lesson: res.data.data.prev_has_data_lesson,
          prev_has_data_client_trend: res.data.data.prev_has_data_client_trend,
        })
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
  },

  // survey
  async getSurveyByScene(context, scene){
    context.commit("clearSurvey")
    await httpClient
      .get(`/cli/floor/surveys/get_by_scene.json?use_scene=${scene}`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          let survey = res.data.data.survey
          let survey_questions = res.data.data.survey_questions
          if(survey) survey.survey_questions = _.sortBy(survey_questions, "sort")
          context.commit("setSurvey", survey)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },
  async getSurveyById(context, uid){
    context.commit("clearSurvey")
    await httpClient
      .get(`/cli/floor/surveys/${uid}.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          let survey = res.data.data.survey
          let survey_questions = res.data.data.survey_questions
          survey.survey_questions = _.sortBy(survey_questions, "sort")
          context.commit("setSurvey", survey)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },

  async getTrainingEvents(context){
    await httpClient
      .get(`/cli/floor/training_events.json`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          context.commit("setTrainingCategories", res.data.data.training_categories)
          context.commit("setTrainingEvents", res.data.data.training_events)
          context.commit("setTrainingMuscles", res.data.data.training_muscles)
          context.commit("setTrainingMachines", res.data.data.training_machines)
          context.commit("setClientTrainingEvents", res.data.data.client_training_events)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
  },

}
